import React from "react"

import "./SectionTwo.scss"

const SectionTwo = () => {
  return (
    <div id="fabwork-section-two">
      <div id="content">
        <div className="section-two-text">
          <h1 className="text-h1">
            Somos membros do ecossistema Intel AI Builders
          </h1>
          <p className="text-p">
            O ecossistema Intel AI Builders é formado por empresas de todo o
            mundo que desenvolvem e adotam tecnologias com inteligência
            artificial (IA), incluindo as gigantes IBM, Cisco, Huawei e Lenovo.
          </p>
        </div>
        <div className="section-two-button">
          <a
            href="https://builders.intel.com/ai/"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <button className="btn">conheça o selo</button>
          </a>
        </div>
      </div>
    </div>
  )
}

export default SectionTwo
