import React from "react"
import Companies from "../../Companies/Companies"
// import { Container } from './styles';

const SectionFour = () => {
  return (
    <div id="fabwork-section-four">
      <Companies noBackground />
    </div>
  )
}

export default SectionFour
