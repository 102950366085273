import React, { useEffect } from "react"
import AOS from "aos"
import "aos/dist/aos.css"

import "./SectionFive.scss"

const SectionFive = ({ style }) => {
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 1000,
      easing: "ease-in-out",
    })
  }, [])
  return (
    <div id="fabwork-section-five" data-aos="fade-up" style={style}>
      <div className="pillars">
        <div data-aos="fade-right">
          <section className="pillar">
            <div className="title">
              <h1>Inspiramos</h1>
            </div>
            <p>com criatividade</p>
          </section>
        </div>
        <div data-aos="fade-right">
          <section className="pillar" data-aos="fade-right">
            <div className="title">
              <h1>Engajamos</h1>
            </div>
            <p>com a mão na massa</p>
          </section>
        </div>
        <div data-aos="fade-right">
          <section className="pillar" data-aos="fade-right">
            <div className="title">
              <h1>Conectamos</h1>
            </div>
            <p>com propósito</p>
          </section>
        </div>
        <div data-aos="fade-right">
          <section className="pillar" data-aos="fade-right">
            <div className="title">
              <h1>Empoderamos</h1>
            </div>
            <p>com conhecimento</p>
          </section>
        </div>
        <div data-aos="fade-right">
          <section className="pillar" data-aos="fade-right">
            <div className="title">
              <h1>Impactamos</h1>
            </div>
            <p>com viabilidade</p>
          </section>
        </div>
      </div>
    </div>
  )
}

export default SectionFive
