import React from "react"

import "./SectionOne.scss"
import ambiente from "../../../images/mobile/afabwork/ambiente.png"
import builders from "../../../images/mobile/afabwork/builders.svg"
import buildersLarge from "../../../images/mobile/afabwork/builders-large.svg"

import arrow from "../../../images/mobile/afabwork/arrow10.svg"
import arrowLarge from "../../../images/mobile/afabwork/arrow10-large.svg"

const SectionOne = () => {
  return (
    <div id="fabwork-section-one">
      <div id="content">
        <div className="section-one-text">
          <p>
            Nossa missão é viabilizar a transformação digital através do
            desenvolvimento tecnológico e de capacitações customizadas para a
            sua realidade.
          </p>
        </div>
        <div className="section-one-image">
          <img src={ambiente} alt="Missão" />
          <div className="section-one-member">
            <img src={arrow} alt="Missão" className="arrow bounce-infinite" />
            <img
              src={arrowLarge}
              alt="Missão"
              className="arrowLarge bounce-infinite"
            />

            <img src={builders} alt="" className="builders rotate-infinite" />
            <img
              src={buildersLarge}
              alt=""
              className="buildersLarge rotate-infinite"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SectionOne
