import React, { useEffect } from "react"
import AOS from "aos"
import "aos/dist/aos.css"

import alefe from "../../../images/pictures/time/alefe.png"
import flavio from "../../../images/pictures/time/flavio.png"
import miguel from "../../../images/pictures/time/miguel.png"
import matheus from "../../../images/pictures/time/matheus.png"
import gabriela from "../../../images/pictures/time/gabriela.png"
import miguel_2 from "../../../images/pictures/time/miguel_2.png"
import leonardo from "../../../images/pictures/time/leonardo.png"
import nayenny from "../../../images/pictures/time/nayenny.png"
import bianca_patricia from "../../../images/pictures/time/bianca_patricia.jpg"
import italo from "../../../images/pictures/time/italo.png"
import gustavo from "../../../images/pictures/time/gustavo_silva.jpeg"
import abmael from "../../../images/pictures/time/abmael.jpg"
import laricia from "../../../images/pictures/time/laricia.jpg"
import maganez from "../../../images/pictures/time/maganez.jpeg"
import nathan from "../../../images/pictures/time/nathan.jpeg"
import joao from "../../../images/pictures/time/joao.jpeg"

import "./SectionSix.scss"
import Carousel from "../../Carousel/Carousel"

const SectionSix = ({ style }) => {
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 1000,
      easing: "ease-in-out",
    })
  }, [])
  return (
    <div id="fabwork-section-six" data-aos="fade-up" style={style}>
      <h1 id="title" data-aos="fade-up" data-aos-delay="300">
        Conheça nosso time
      </h1>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        data-aos="fade-up"
        data-aos-delay="500"
      >
        <Carousel
          team={[
            {
              name: "Miguel Isoni",
              image: miguel_2,
              role: "Chairman",
              linkedin:
                "https://www.linkedin.com/in/miguel-maur%C3%ADcio-isoni-73933219/",
            },
            {
              name: "Miguel Isoni Filho",
              image: miguel,
              role: "CEO",
              linkedin: "https://www.linkedin.com/in/miguelisonifilho/",
            },
            {
              name: "Gabriela Chaves",
              image: gabriela,
              role: "Head de Marketing & Comunicação",
              linkedin: "https://www.linkedin.com/in/gabichaves/",
            },
            {
              name: "Leonardo Ebling",
              image: leonardo,
              role: "Head de Negócios & Produtos",
              linkedin: "https://www.linkedin.com/in/leonardoebling/",
            },
            {
              name: "Álefe Moreira",
              image: alefe,
              role: "Junior Software Developer",
              linkedin: "https://www.linkedin.com/in/%C3%A1lefe-moreira/",
            },
            {
              name: "Bianca Patrícia",
              image: bianca_patricia,
              role: "Analista de Marketing",
              linkedin: "https://www.linkedin.com/in/biancapatricia/",
            },
            {
              name: "Flávio Crispin",
              image: flavio,
              role: "Data Scientist",
              linkedin: "https://www.linkedin.com/in/flaviocrispin/",
            },
            {
              name: "Matheus Crispim",
              image: matheus,
              role: "Tech Lead",
              linkedin: "https://www.linkedin.com/in/matheus-crispim/",
            },
            {
              name: "Nayenny Rodrigues",
              image: nayenny,
              role: "Designer FABWORK",
              linkedin: "https://www.linkedin.com/in/nayennyrodrigues/",
            },
            {
              name: "Ítalo Oliveira",
              image: italo,
              role: "Data Scientist",
              linkedin: "https://www.linkedin.com/in/italo-de-pontes-oliveira/",
            },
            {
              name: "Gustavo Silva",
              image: gustavo,
              role: "Analista Comercial",
              linkedin: "https://www.linkedin.com/in/jsgustavo/",
            },
            {
              name: "Abmael Bandeira",
              image: abmael,
              role: "Backend Developer",
              linkedin: "https://www.linkedin.com/in/abmaelbandeira/",
            },
            {
              name: "Laricia Barbosa",
              image: laricia,
              role: "Designer UX/UI",
              linkedin:
                "https://www.linkedin.com/in/j-laricia-ferreira-barbosa-3909b718a/",
            },
            {
              name: "Maganez Filho",
              image: maganez,
              role: "Frontend Developer",
              linkedin: "https://www.linkedin.com/in/maganezf/",
            },
            {
              name: "Nathan Fernandes",
              image: nathan,
              role: "Analista Comercial",
              linkedin: "https://www.linkedin.com/in/maganezf/",
            },
            {
              name: "João Paulo Pessoa",
              image: joao,
              role: "Full Stack Developer Jr",
              linkedin: "https://www.linkedin.com/in/maganezf/",
            },
          ]}
        />
        <p id="text">Como podemos transformar sua realidade digital?</p>

        <a href="/contact">
          <button id="rating" className="btn">
            Entre em contato
          </button>
        </a>
      </div>
    </div>
  )
}

export default SectionSix
