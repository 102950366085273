import React from "react"

import Carousel from "../../Carousel/Carousel"

import "./SectionSeven.scss"

const SectionSeven = ({ style }) => {
  return (
    <div id="fabwork-section-seven" style={{ ...style }}>
      <h1 id="title">Nossos embaixadores</h1>
      <Carousel />
    </div>
  )
}

export default SectionSeven
