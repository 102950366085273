import React from "react"
import { Helmet } from "react-helmet"
import RDStationConection from "../utils/RDStationConnection"

import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"

import SectionOne from "../components/Fabwork/SectionOne/SectionOne"
import SectionTwo from "../components/Fabwork/SectionTwo/SectionTwo"
import SectionThree from "../components/Fabwork/SectionThree/SectionThree"
import SectionFour from "../components/Fabwork/SectionFour/SectionFour"
import SectionFive from "../components/Fabwork/SectionFive/SectionFive"
import SectionSix from "../components/Fabwork/SectionSix/SectionSix"
import SectionSeven from "../components/Fabwork/SectionSeven/SectionSeven"
import NeoronWidget from "../utils/NeoronWidget"

export default function Fabwork() {
  React.useEffect(() => {
    NeoronWidget.init()
    RDStationConection.init()
  }, [])
  return (
    <div style={{ width: "100%" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sobre a FABWORK</title>
        <link rel="canonical" href="https://fab.work/fabwork/" />
      </Helmet>
      <Header />
      {/*<div style={{ display: "none" }}></div>*/}
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <SectionFive />
      <SectionSix />
      <SectionSeven style={{ display: "none" }} />
      <Footer />
    </div>
  )
}
